import AuthInfo from '@/models/auth-info'
import repository from '@/repositories/repository'

export const BASE_URL = '/api/v1'

/**
 * Cookieに認証情報をセット
 * @param {import('axios').AxiosResponse} response 取得APIのレスポンス
 */
export const setAuthenticateInfo = (response) => {
  localStorage.setItem('access_token', response.data.body.access_token)
  localStorage.setItem('refresh_token', response.data.body.refresh_token)
  localStorage.setItem('expires_in', response.data.body.expires_in)
}

/**
 * Cookieから認証情報を削除
 */
export const removeAuthenticateInfo = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('expires_in')
}

/**
 * CookieにUserMe情報をセットし、取得情報をモデルにセットして返却
 * @param {import('axios').AxiosResponse} response 取得APIのレスポンス
 * @return {AuthInfo} 認証情報モデル
 */
export const setUserMe = (response) => {
  localStorage.setItem('tenant_id', response.data.body.tenant_id)
  localStorage.setItem('company_id', response.data.body.company_id)
  localStorage.setItem('user_id', response.data.body.user_id)
  localStorage.setItem('role', response.data.body.role)
  return new AuthInfo({
    userId: response.data.body.user_id,
    userName: response.data.body.user_name,
    tenantId: response.data.body.tenant_id,
    companyId: response.data.body.company_id,
    role: response.data.body.role,
    s3Bucket: response.data.body.s3_bucket,
    websocketEndpoints: response.data.body.websocket_endpoints,
  })
}

/**
 * CookieからUserMe情報を削除
 */
export const removeUserMe = () => {
  localStorage.removeItem('tenant_id')
  localStorage.removeItem('company_id')
  localStorage.removeItem('user_id')
  localStorage.removeItem('role')
}

export const isAccessToken = () => {
  if ( localStorage.getItem('access_token') ) {
    return true
  } else {
    return false
  }
}

export const getAuthenticateHeader = () => {
  return {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
  }
}

export const TOKEN_REFRESH_URL = `${BASE_URL}/token:refresh`

export const postTokenRefresh = async () => {
  try {
    const tokenRefreshRequest = {
      refresh_token: localStorage.getItem('refresh_token'),
    }
    const response = await repository.post(TOKEN_REFRESH_URL, tokenRefreshRequest)
    setAuthenticateInfo(response)
    return response
  } catch(error) {
    // トークン更新に失敗したら、cookieの認証情報を削除しておく
    removeAuthenticateInfo()
    removeUserMe()
    return Promise.reject(error)
  }
}
