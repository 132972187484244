import ImgInfo from '@/models/img-info'
import repository from '@/repositories/repository'

const basePath = () => {
  return `/api/v1/tenants/${localStorage.getItem("tenant_id")}/companies/${localStorage.getItem("company_id")}/missions`
}

const toModelImgInfo = ({
  image_id,
  image,
}) => {
  return new ImgInfo({
    imgId: image_id,
    imgSrc: image
  })
}

const toModelImgInfos = (imgfos) => {
  let modelImgInfos = []

  if (imgfos != null) {
    imgfos.forEach( function(ImgInfo) {
      modelImgInfos.push(toModelImgInfo(ImgInfo))
    })
  }

  return modelImgInfos
}

export default {
  getImgInfos(missionId, startIndex, conut) {
    const params = {
      'start_index': startIndex,
      'count': conut,
    }

    return repository.get(`${basePath()}/${missionId}/thumbnail_images`, {params})
      .then(function (response) {
        return toModelImgInfos(response.data.body)
      })
      .catch(function (error) {
        return Promise.reject(error)
      })
  },
  getImgInfo(missionId, imgId, callback) {
    const resultImgId = encodeURIComponent(imgId)
    repository.get(`${basePath()}/${missionId}/images/${resultImgId}/original_image`)
      .then(function (response) {
        callback(response.data.body)
      })
      .catch(function (error) {
        console.error(error)
      })
      .finally(function () {})
  },
}
