import PilotInfo from '@/models/pilot-info'
import repository from '@/repositories/repository'

const tenantId = () => {
  return localStorage.getItem('tenant_id')
}
const companyId = () => {
  return localStorage.getItem('company_id')
}
const pilotId = () => {
  return localStorage.getItem('user_id')
}
const basePath = () => {
  return `/api/v1`
}

const toModelPilotInfo = ({
  id,
  status,
  created,
  first_name,
  last_name,
  middle_name,
  email,
  multi_factor_auth,
  role,
}) => {
  return new PilotInfo({
    pilotId: id,
    activateStatus: status,
    created: created,
    firstName: first_name,
    lastName: last_name,
    middleName: middle_name,
    email: email,
    multiFactorAuth: multi_factor_auth,
    role: role,
  })
}

const toModelPilotInfos = (pilotInfos) => {
  let modelPilotInfos = []

  pilotInfos.forEach( function(pilotInfo) {
    modelPilotInfos.push(toModelPilotInfo(pilotInfo))
  })

  return modelPilotInfos
}

export default {
  getPilotInfos(callback) {
    repository.get(`${basePath()}/pilots?tenant_id=${tenantId()}&company_id=${companyId()}&user_id=${pilotId()}`)
      .then(function (response) {
        callback(toModelPilotInfos(response.data.body))
      })
      .catch(function (error) {
        console.error(error)
      })
      .finally(function () {})
  },
  createPilotInfo(pilotInfo) {
    if (pilotInfo instanceof PilotInfo) {
      const createPilotInfoRequest = {
        first_name: pilotInfo.firstName,
        last_name: pilotInfo.lastName,
        middle_name: pilotInfo.middleName,
        email: pilotInfo.email,
        multi_factor_auth: pilotInfo.multiFactorAuth,
      }

      return repository.post(`${basePath()}/pilot?tenant_id=${tenantId()}&company_id=${companyId()}&user_id=${pilotId()}`, createPilotInfoRequest)
    }
  },
}
