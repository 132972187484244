export default {
  'list-title': 'ミッション一覧',
  'create-title': 'ミッション作成',
  'edit-title': 'ミッション編集',
  'detail-title': 'ミッション詳細',
  'create-button': '作成',
  'edit-button': '編集',
  'updata-button': '編集',
  'delete-button': '削除',
  'selection-button': '選択',
  'all-missions': '全てのミッション',
  'my-missions': '自分の担当ミッション',
  'form': {
    'placeholder': {
      'mission-name': 'ミッション名',
      'mission-date': '実施日',
      'location-lat': '経度',
      'location-lng': '緯度',
      'now-data': '今日',
    },
    'select-text': 'の選択...',
  },
  'error-message': {
    'select-pilot': 'パイロットを選択してください',
    'enter-mission-name': 'ミッション名を入力してください',
    'enter-implementation-date': '実施日を入力してください',
    'creation-update-failed': '作成・更新に失敗しました',
  },
  'index': {
    'caption': '最新のミッション一覧',
    'header': {
      'mission-title': 'ミッション名',
      'mission-date': '実施日',
      'mission-pilot': 'パイロット',
      'status': 'ステータス',
    },
  },
  'list': {
    'caption': 'ミッション一覧',
    'header': {
      'mission-title': 'ミッション名',
      'mission-date': '実施日',
      'mission-pilot': 'パイロット',
      'status': 'ステータス',
    },
  },
  'detail': {
    'mission-name': 'ミッション名',
    'mission-date': '実施日',
    'pilot-name': 'パイロット名',
    'location': '場所',
    'location-lat': '経度',
    'location-lng': '緯度',
    'status': 'ステータス',
    'flight-info': {
      'flight-title': 'フライト情報',
      'timestamp': 'タイムスタンプ',
      'Location': {
        'Location': '位置情報',
        'gps': 'GPS',
        'rtk': 'RTK',
      },
      'direction': '方向',
      'networkType': 'ネットワークタイプ',
      'flightStatus': 'フライトステータス',
      'rfStrength': '無線周波数',
      'cellInfo': 'セル情報',
      'flightTime': '飛行時間',
      'Battery': {
        'Battery': 'バッテリー',
        'drone': 'ドローン',
        'camera': 'カメラ',
        'android': 'Android',
      },
      'beacon': 'ビーコン',
      'applicationStatus': 'アプリケーションステータス',
      'android': {
        'android': 'Android',
        'SysTemp': 'システム',
        'FanOutput': 'ファン出力',
      },
      'DiskSpace': {
        'DiskSpace': 'ディスク容量',
        'camera': 'カメラ',
        'android': 'Android',
      },
      'statusCode': 'ステータスコード',
    },
    'img-info': {
      'img-title': '写真',
      'modal-close': 'Close',
      'thumbnail-img': 'のサムネイル画像',
      'original-img': 'のオリジナル画像',
    },
    'live-view': {
      'live-view-title': 'ライブビュー',
      'live-view-start-button': 'ストリーミング開始',
      'live-view-stop-button': 'ストリーミング停止',
    },
  },
  'status': {
    'planned': '予定',
    'ongoing': '進行中',
    'finished': '終了',
  },
}
